<template>
  <div>
    <div class="container" v-if="agree && initOk">
      <van-divider
        v-if="id == undefined"
        :style="{
          borderColor: '#ee0a24',
          padding: '0 100px',
        }"
      >
        注意事项
      </van-divider>
      <div class="info" v-if="id == undefined">
        学校为了满足学生发展的需要，允许学生转专业就读，经学校批准后，学籍上报和毕业信息均以转入专业为准，每个学生只有一次转专业机会，请慎重考虑后提交申请。
      </div>
      <van-notice-bar
        v-if="id && status == 'PENDING'"
        left-icon="volume-o"
        wrapable
        :scrollable="false"
        text="请等待教务处审核"
      />

      <van-notice-bar
        v-if="id && status == 'SUCCEED'"
        left-icon="volume-o"
        wrapable
        :scrollable="false"
        text="您的申请已通过审核"
      />

      <van-notice-bar
        v-if="id && status == 'FAILED'"
        left-icon="volume-o"
        wrapable
        :scrollable="false"
        :text="'您的申请未通过审核，有任何疑问请联系我们！' + approve"
      />
      <van-form @submit="onSubmit">
        <div>
          <p class="input-info">原专业</p>
          <van-field
            :disabled="id == undefined ? false : true"
            readonly
            v-model="fromMajor"
            name="fromMajor"
            placeholder="请输入原专业"
          />
          <p class="input-info">新专业</p>
          <van-field
            :disabled="id == undefined ? false : true"
            readonly
            clickable
            name="toMajor"
            :value="toMajor"
            placeholder="请选择"
            @click="showToMajorPicker = true"
            :rules="[
              {
                required: true,
                message: '请选择新专业',
              },
            ]"
          />
          <van-popup
            v-if="id == undefined"
            v-model="showToMajorPicker"
            position="bottom"
          >
            <van-picker
              show-toolbar
              :columns="toMajorColumns"
              @confirm="onToMajorConfirm"
              @cancel="showToMajorPicker = false"
            />
          </van-popup>

          <p class="input-info">申请原因</p>
          <van-field
            :disabled="id == undefined ? false : true"
            name="reasons"
            :rules="[{ required: true, message: '请选择申请原因' }]"
          >
            <template #input>
              <van-checkbox-group
                v-model="reasons"
                :disabled="id == undefined ? false : true"
              >
                <van-checkbox name="1" shape="square"
                  >1：志愿填报欠缺考虑，原专业学习兴趣不足</van-checkbox
                >
                <van-checkbox name="2" shape="square"
                  >2：与个人升学就业发展规划切合度不高</van-checkbox
                >
                <van-checkbox name="3" shape="square"
                  >3：经过详细咨询和深思熟虑</van-checkbox
                >
                <van-checkbox name="4" shape="square"
                  >4：父母亲朋的建议，征求多人意见</van-checkbox
                >
                <van-checkbox name="5" shape="square"
                  >5：充分了解新专业的发展情况，经过慎重考虑后选择</van-checkbox
                >
              </van-checkbox-group>
            </template>
          </van-field>
          <p class="input-info">其它情况说明</p>
          <van-field
            :disabled="id == undefined ? false : true"
            name="memo"
            v-model="memo"
            rows="2"
            autosize
            type="textarea"
            placeholder="请输入其它情况说明"
          />
          <div v-if="id == undefined" style="margin: 16px">
            <van-button round block type="danger" native-type="submit"
              >提交</van-button
            >
          </div>
          <div v-else-if="id && status != 'SUCCEED'" style="margin: 16px">
            <van-button
              round
              block
              type="default"
              native-type="button"
              @click="cancel()"
              >取消申请</van-button
            >
          </div>
        </div>
      </van-form>
    </div>
    <div v-else class="agreement">
      <h2 class="text-center">注意事项</h2>
      <div class="agreement-content">
        <p>
          1.按照国家学籍管理规定，新生获得学籍后方可办理转专业手续，转专业办理时间为新生入学的第一个学期期末。
        </p>
        <p>
          2.转专业申请获批后可在入学第一周（入学教育周）到转入专业进行课程体验学习，期末再办理正式转专业手续。
        </p>
        <p>3.每位在校生仅有一次课程体验和转专业申请机会，请慎重考虑后提交。</p>
        <p>
          4.转专业新生报到注册须按照录取专业报到、缴费和选宿舍，所缴费用在第一学年结束后再进行清算，宿舍由学校统一指令调整。
        </p>
        <p>5.以上规定由教务处负责释义，电话：0771-4797125</p>
      </div>
      <van-button
        block
        type="danger"
        @click="
          () => {
            agree = true;
          }
        "
        >同意</van-button
      >
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import { Notify } from "vant";
import * as api from "@/apis/api";
export default {
  data() {
    return {
      agree: false,
      initOk: false,
      id: undefined,
      status: undefined,
      fromMajor: undefined,
      toMajor: "",
      toMajorColumns: [],
      showToMajorPicker: false,

      reasons: [],
      memo: undefined,
    };
  },
  computed: {
    loginUser() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.findMajorReq();
    api.orgListForMajorReq().then((res) => {
      this.toMajorColumns = res;
    });
    this.fromMajor = this.loginUser.majorName;
  },
  watch: {
    $route(to, from) {
      console.log(from.path); //从哪来
      console.log(to.path); //到哪去
      this.agree = false;
      this.findMajorReq();
    },
  },
  methods: {
    findMajorReq() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.majorReq().then((res) => {
        if (res.id) {
          this.id = res.id;
          this.status = res.status;
          this.toMajor = res.toMajor;
          this.reasons = JSON.parse(res.reason);
          this.memo = res.memo;
        } else {
          this.id = undefined;
        }
        Toast.clear();
        this.initOk = true;
      });
    },
    onToMajorConfirm(value) {
      this.toMajor = value;
      this.showToMajorPicker = false;
    },
    onSubmit(values) {
      values.reason = JSON.stringify(values.reasons);
      api.insertMajorReq(values).then((res) => {
        if (res.state == "ok") {
          Toast.success("提交成功，请等待审核");
          // this.findMajorReq();
          this.$router.push({ path: "/me", query: {} });
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
      console.log("submit", values);
    },
    cancel() {
      Dialog.confirm({
        title: "提示",
        message: "您确认取消吗？",
      })
        .then(() => {
          api.cancelMajorReq().then((res) => {
            if (res.state == "ok") {
              this.findMajorReq();
              Toast.success("成功取消");
            } else {
              Notify({ type: "danger", message: res.msg });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 0px 0px 10px 0px;
}
.info {
  margin-bottom: 10px;
  padding: 10px;
  text-indent: 2em;
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #ed6a0c;
  background-color: #fffae5;
}
.input-info {
  padding: 10px;
  margin: 0;
  font-size: 14px;
}
.van-checkbox {
  margin-bottom: 10px;
  font-size: 14px;
}
.van-checkbox:last-child {
  margin-bottom: 0px;
}
.agreement {
  padding: 10px;
}
.agreement-content p {
  text-indent: 2em;
  font-size: 0.9em;
}
.agreement-content {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #eee;
  background-color: #fff;
  margin-bottom: 10px;
}
.text-center {
  text-align: center;
}
</style>
